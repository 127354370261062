import React, { useState } from "react";
import Image from "next/image";
//import NavBurger from '@/lib/Layout/NavBar/NavBurger'
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import Menu from "@/lib/Layout/NavBar/Menu";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import NavBurger2 from "./NavBurger2";
//import NavMenu2 from './NavMenu2'
import SayHello from "../Authentication/SayHello";
import {
  Icon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  useMediaQuery,
} from "@chakra-ui/react";
import { FiShoppingCart } from "react-icons/fi";
import { logEvent } from "@/analytics";
import { useRouter } from "next/router";
import useGetCartCount from "@/lib/hooks/useGetCartCount";
import useCheckLogin from "@/lib/hooks/useCheckLogin";

interface NavBarProps {
  mode: string;
  navBarConfig?: navBarConfig;
}
export type logoColor = "black" | "white" | "primary";
type navTextColor = Omit<logoColor, "primary">;
export type navBarConfig = {
  logoType: { bigScreen: logoColor; smallScreen: logoColor };
  linkColor: { bigScreen: navTextColor; smallScreen: navTextColor };
};
const NavBar2 = ({
  mode,
  navBarConfig = {
    logoType: { bigScreen: "primary", smallScreen: "primary" },
    linkColor: { bigScreen: "black", smallScreen: "black" },
  },
}: NavBarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [whatWeTreatPopUp, setWhatWeTreatPopUp] = useState(false);
  const navigation = useRouter();
  const { cartQty, isLoading: cartQtyLoading } = useGetCartCount();
  const { data: isLoggedIn } = useCheckLogin();
  const [isSmallScreen] = useMediaQuery("(max-width: 500px)");

  const getLogo = (type: logoColor) => {
    switch (type) {
      case "primary":
        return "https://assets.bonatra.com/images/bonatra-raush-logo.webp";
      case "black":
        return "https://storage.googleapis.com/bonatra-assets/BonatraApplicationAssets/Bonatra%20Black%20Logo.webp";
      case "white":
        return "https://storage.googleapis.com/bonatra-assets/BonatraApplicationAssets/Bonatra%20White%20Logo.webp";
      default:
        return "https://assets.bonatra.com/images/bonatra-raush-logo.webp";
    }
  };
  const isBioForm = navigation.pathname === "/biologicalAgeCalculator";
  const logoColor = isSmallScreen
    ? navBarConfig?.logoType?.smallScreen
    : navBarConfig?.logoType?.bigScreen;
  const logo = getLogo(logoColor);
  const linkColor = isSmallScreen
    ? navBarConfig?.linkColor?.smallScreen
    : navBarConfig?.logoType?.bigScreen;
  const themeMode = isBioForm ? (isSmallScreen ? "dark" : "light") : mode;
  const isDark = themeMode === "dark" || linkColor === "white";

  return (
    <div
      className={`w-screen p-8 py-10 mx-auto font-normal h-10 absolute top-0 flex justify-between text-base items-center ${
        isDark ? "text-white bg-black" : "text-black"
      }`}
    >
      <AnimatePresence>
        {whatWeTreatPopUp && (
          <motion.div
            exit={{ y: [0, 20], opacity: 0 }}
            animate={{ y: [20, 0], opacity: [0, 1] }}
            className="absolute w-screen top-10 z-50"
          >
            <Menu />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="flex gap-2 md:gap-8 items-center ">
        <div
          className="flex gap-2 md:gap-8 cursor-pointer -translate-y-[2px]"
          onClick={() => navigation.push("/")}
        >
          <Image
            src={logo}
            className={""}
            alt="logo"
            height={isSmallScreen ? 60 : 100}
            width={isSmallScreen ? 60 : 100}
            priority
            style={{ objectFit: "contain" }}
          />
        </div>

        <nav className="">
          <ul className="flex flex-row gap-2 md:gap-8">
            <li
              id="nav_bar_what_we_treat"
              className={`hidden md:hidden md:flex cursor-pointer p-2 px-4 rounded-full ${
                isDark ? "border-white bg-black" : "border-black bg-white"
              } flex items-center gap-4`}
              onClick={() => {
                setWhatWeTreatPopUp((p) => !p);
                logEvent("Navbar", "What We Treat");
                (window as any).fbq("trackCustom", "NAVBAR_WHAT_WE_TREAT");
              }}
            >
              <div className="flex items-center">
                Find My Age
              </div>
            </li>
            <li
              id="nav_bar_store"
              className={`font-semibold md:font-normal cursor-pointer p-2 px-4  rounded-full md:border md:border-solid md:border-black ${
                isDark ? "border-white" : "border-black"
              }`}
            >
              <Link
                href="/all-products"
                onClick={() => {
                  logEvent("Navbar", "Store");
                  (window as any).fbq("trackCustom", "NAVBAR_STORE");
                }}
              >
                Store
              </Link>
            </li>
            <li
              id="nav_bar_say_hello"
              onClick={onOpen}
              className={`cursor-pointer md:font-normal font-semibold p-2 px-4 md:border md:border-solid md:border-black rounded-full ${
                isDark ? "border-white" : "border-black"
              }`}
            >
              Say Hello!
            </li>
          </ul>
        </nav>
      </div>

      <div className="flex gap-6 md:gap-8 text-center items-center">
        <Modal
          colorScheme="blackAlpha"
          isOpen={isOpen}
          onClose={onClose}
          closeOnEsc={true}
          size={"5xl"}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton zIndex={10000} top={0} right={0} />
            <ModalBody padding="1.5rem 1.5rem">
              {/* <NewLeadsForm onClose={onClose} /> */}
              <SayHello />
            </ModalBody>
          </ModalContent>
        </Modal>

        <nav className=" gap-2 md:gap-8 ">
          <ul className="flex gap-4 md:gap-8 items-center">
            <li className="cursor-pointer hidden md:flex">
              <a
                href="https://blog.bonatra.com/"
                onClick={() => {
                  logEvent("Navbar", "Blogs");
                  (window as any).fbq("trackCustom", "NAVBAR_BLOGS");
                }}
              >
                Blogs
              </a>
            </li>

            <li className="cursor-pointer hidden md:flex">
              <Link
                href="/bonatra-team"
                onClick={() => {
                  logEvent("Navbar", "Teams");
                  (window as any).fbq("trackCustom", "NAVBAR_TEAMS");
                }}
              >
                Team
              </Link>
            </li>

            {isLoggedIn ? (
              true && (
                <li className="relative">
                  <div>
                    <IconButton
                      aria-label="favourites"
                      icon={<Icon as={FiShoppingCart} w={5} h={6} />}
                      variant="outline"
                      border="none"
                      onClick={() => {
                        logEvent("Products", "Cart");
                        (window as any).fbq("trackCustom", "Cart");
                        navigation.push("/cart");
                      }}
                    />
                  </div>
                  {cartQty !== 0 && (
                    <motion.div
                      initial={{ y: -25, x: 18 }}
                      className="absolute flex justify-center items-center bg-primary p-1 h-6 w-6 text-xs rounded-full text-white font-bold"
                    >
                      {cartQty || "0"}
                    </motion.div>
                  )}
                </li>
              )
            ) : (
              <li className="flex gap-4">
                <div
                  className={`hidden md:flex cursor-pointer p-2 px-4  rounded-full ${
                    isDark ? "border-white" : "border-black"
                  }`}
                >
                  <Link href="/login">Login</Link>
                </div>
                <div
                  className={`hidden md:flex cursor-pointer p-2 px-4  rounded-full ${
                    isDark ? "border-white" : "border-black"
                  }`}
                >
                  <Link href="/signup">Sign Up</Link>
                </div>
              </li>
            )}
          </ul>
        </nav>
        <NavBurger2 mode={themeMode} />
      </div>
    </div>
  );
};

export default NavBar2;
