import { slideChildrenIn } from "@/lib/AnimationComponents/variants";
import Section from "@/lib/Layout/Wrappers/Section";
import useZustandStore from "@/lib/store/useZustandStore";
import { motion } from "framer-motion";
import React from "react";
import router, { useRouter, } from "next/router";
import useLogout from "@/lib/hooks/AuthHooks/useLogout";
import { Button, useToast } from "@chakra-ui/react";
import useCheckLogin from "@/lib/hooks/useCheckLogin";
import NavBurger2 from "./NavBurger2";

const fadeInVariants = slideChildrenIn();

const NavMenu2 = () => {
    const setNavBurgerState = useZustandStore(state => state.setNavBurgerState);
    const { closeNavBurger, navBurgerState } = useZustandStore(state => state)
    const { mutate: logoutMutate } = useLogout()
    const { data: isLoggedin } = useCheckLogin()

    const navigation = useRouter()
    const toast = useToast();

    const handleLogin = () => {
        navigation.push("/login");
        closeNavBurger();
    }
    const handleLogout = () => {
        localStorage.removeItem('token');
        logoutMutate("dummy", {
            onSuccess: () => {
                toast({
                    title: "Logged out successfully",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                });
                window?.location?.reload()
            },
            onError: (error) => {
                console.log("fail to log user out", error)
            }
        })
    }

    const buttonText = isLoggedin ? "Logout" : "Login"


    const handleRoutes = (index: number) => {
        closeNavBurger()
        router.push({
            pathname: `${navlinksMap[index]?.link}`,
        }, undefined, { shallow: true })

    }

    const isRingPreorderPage = navigation.pathname === "/bonatra-ring-x1"
    return (
        <motion.div
            variants={bgVariant}
            initial="hidden"
            animate="show"
            exit="exit"
            className="fixed h-screen w-screen top-0 left-0 bg-white text-black z-[999] "
        >

            <Section className="relative z-[999]">
                <motion.div className="absolute right-10"><NavBurger2 mode="light" /></motion.div>
                <nav className="fixed top-40 left-0 w-screen h-screen p-4 ">
                    <motion.ul
                        variants={fadeInVariants.container}
                        initial="hidden"
                        animate="show"
                        className="flex flex-col flex-wrap max-h-[40rem] md:max-h-[30rem] gap-7 md:content-around content-start px-8 md:px-20"
                    >
                        {navlinksMap?.map((link, idx) => (
                            <motion.li variants={fadeInVariants.child}
                                className="flex flex-col gap-8 items-start"
                                key={link.link}>
                                <motion.div
                                    className=" font-regular md:text-2xl text-xl cursor-pointer hover:underline underline-offset-8 "
                                >
                                    <motion.div className="flex flex-col gap-8 items-start" whileHover={{ y: -4 }}>
                                        <Button variant="unstyled" key={idx} onClick={() => handleRoutes(idx)}>{link.name}</Button>
                                    </motion.div>
                                </motion.div>
                                {idx === navlinksMap?.length - 1 && <button className="w-36 bg-black h-10 text-white text-2xl rounded-md" onClick={() => { closeNavBurger(); isLoggedin ? handleLogout() : handleLogin() }}>{buttonText}</button>}
                                {(idx === navlinksMap?.length - 1 && !isLoggedin) && <button className="w-36 bg-black h-10 text-white text-2xl rounded-md" onClick={() => { closeNavBurger(); navigation.push("/signup"); }}>Signup</button>}
                            </motion.li>
                        ))}
                    </motion.ul>
                </nav>

            </Section>
        </motion.div >
    );
};

export default NavMenu2;


const navlinksMap = [{
    name: "Home",
    link: "/",
    queryId: ""
},
{
    name: "Bonatra Ring X1",
    link: "/bonatra-ring-x1",
    queryId: ""
},
{
    name: "Bonatra Team",
    link: "/bonatra-team",
    queryId: ""
},
{
    name: "Store",
    link: "/all-products",
    queryId: ""
},    
{
    name: "About",
    link: "/about-us",
    queryId: ""
},
{
    name: "Contact",
    link: "/contact",
    queryId: ""
},
{
    name: "Privacy Policy",
    link: "/tnc",
    queryId: ""
},
{
    name: "Blogs",
    link: "https://blog.bonatra.com/",
    queryId: ""
},
{
    name: "Bonatra X Diabetes",
    link: "/package/one-month",
    queryId: ""
},
{
    name: "Bonatra X Fitness",
    link: "/package/fitness",
    queryId: ""
},
{
    name: "Ring Sizer",
    link: "/bonatra-ring-x1-sizer/SizerHome",
    queryId: ""
}
]

const bgVariant = {
    exit: {
        height: "100vh",
        width: "100%",
        clipPath: "circle(30px at 50% 0%)",
        transition: { duration: 0.6 },
        default: { ease: "ease-outw" },
    },
    hidden: {
        height: "100vh",
        width: "100%",
        clipPath: "circle(30px at 50% 0%)",
        transition: { duration: 1 },
        default: { ease: "ease-in" },
    },
    show: {
        height: "100vh",
        width: "100%",
        clipPath: `circle(${1000 * 2 + 200}px at  50% 0%)`,
        transition: { clipPath: { duration: 1 } },
        default: { ease: "ease-out" },
    },
};
