
import { Variants } from "framer-motion"

export const popChildrenIn = () => {
  const container = {
    hidden: { x: 0 },
    show: {
      x: 0,
      transition: {
        staggerChildren: 0.06,
        delayChildren: 0.2,
      },
    },
  }
  const child = {
    hidden: { scale: 0.7 },
    show: {
      scale: 1,

    },
  }
  return { container: container, child: child } as { container: Variants, child: Variants }
}


export const slideChildrenIn = () => {
  const container = {
    hidden: { x: 0 },
    show: {
      x: 0,
      transition: {
        staggerChildren: 0.06,
        delayChildren: 0.2,
      },
    },
  }
  const child = {
    hidden: { x: 20, opacity: 0 },
    show: {
      x: 0,
      opacity: 1

    },
  }
  return { container: container, child: child } as { container: Variants, child: Variants }
}

export const displayChildrenIn = () => {
  const container = {
    hidden: { x: 0 },
    show: {
      x: 0,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0,
      },
    },
  }
  const child = {
    hidden: { opacity: 0, y: 10 },
    show: {
      opacity: 1,
      y: 0
    },
  }
  return { container: container, child: child } as { container: Variants, child: Variants }
}

export const fadeChildrenFromTop = (x: number) => {
  const container = {
    hidden: { y: 0, },
    show: {
      y: 0,

      transition: {
        staggerChildren: 0.3,
        delayChildren: 0,
      },
    },
  }
  const child = {
    hidden: { opacity: 0, y: -120, x: x },
    show: {
      opacity: 1,
      y: 120,
      x: x,
    },
  }
  return { container: container, child: child } as { container: Variants, child: Variants }
}