import React, { VFC, ReactNode, useState } from 'react';
import { Flex, Box, Input, Button, Image, useToast, Text, FormControl, FormErrorMessage } from '@chakra-ui/react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { countryCode } from '@/lib/countryCodes'


const SayHello: VFC = () => {
    const toast = useToast();
    const [countryCodeSearchInput, setCountryCodeSearchInput] = useState(``);
    const [countryCodeModalState, setCountryCodeModalState] = useState(false);
    const [modalBgState, setModalBgState] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({
        "name": "India",
        "region": "Asia",
        "timezones": {
            "Asia/Kolkata": "+05:30"
        },
        "iso": {
            "alpha-2": "IN",
            "alpha-3": "IND",
            "numeric": "356"
        },
        "phone": [
            "+91"
        ],
        "emoji": "🇮🇳",
        "image": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
        "phoneLength": 10
    })

    const validateMobileNumber = (value: string) => {
        const regex = /^[0-9\b]+$/;
        if (!value || value.trim() === "") {
            return "Mobile number is required";
        } else if (value.trim().length !== 10) {
            return "Mobile number should be 10 digits long";
        } else if (!regex.test(value)) {
            return "Mobile number should contain only digits";
        }
        return true;
    };

    const onSubmit = async (values: any) => {
        values["email_id"] = "dummy@test.com";
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams = {
            utmSource: urlParams.get('utm_source'),
            utmMedium: urlParams.get('utm_medium'),
            utmCampaign: urlParams.get('utm_campaign'),
            utmTerm: urlParams.get('utm_term'),
            utmContent: urlParams.get('utm_content'),
        };
        values["utmSource"] = utmParams.utmSource;
        values["utmMedium"] = utmParams.utmMedium;
        values["utmCampaign"] = utmParams.utmCampaign;
        values["utmTerm"] = utmParams.utmTerm;
        values["utmContent"] = utmParams.utmTerm;
        values["mobile_no"] = selectedCountry?.phone + "-" + values.mobile_no;

        try {
            await axios({
                method: 'post',
                url: `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.templates.includes.consultationform.create_lead`,
                data: {
                    type: 'Ring Page',
                    ...values,
                }
            })
            toast({
                title: "Thank you for your interest. Our expert will contact you soon",
                status: "success",
                duration: 3000,
                position: "top",
                isClosable: true,
            });
            reset();
        }
        catch (error) {
            // Handle the error if needed
            console.error("CreateLead Error:", error);
        }
    }

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm();

    const handleSelectCountry = (country) => {
        setSelectedCountry(country)
        toggleCountryCodeModal()
    }

    const countryCodeSearchBar = (
        <input className='w-[15%] h-[3.3125rem] rounded-[0.625rem] mt-[15px] border-[2px] border-solid border-[#D9D9D9]'
            type="text"
            title="countryCode"
            defaultValue={selectedCountry.name + " " + `(${selectedCountry.phone})`}
            placeholder="+91"
            onChange={(e) => setCountryCodeSearchInput(e.target.value)}
            value={countryCodeSearchInput}>
        </input>
    )

    const filteredCountryCodeList = countryCode?.filter(el => el?.name?.toLocaleLowerCase().includes(countryCodeSearchInput.toLocaleLowerCase()))
    const countryCodeList = <div className='absolute z-50 h-96 w-96 overflow-y-scroll shadow-lg bg-white rounded-lg p-4 top-1/2 left-1/2 -translate-x-1/2 -translate'>
        {filteredCountryCodeList?.map(country => {
            const isSelected = selectedCountry.name === country.name
            return <div key={country.name} className="p-2 cursor-pointer rounded-lg" style={{ backgroundColor: isSelected ? "#222" : "#fff", color: isSelected ? "#FFF" : "#222" }}
                onClick={() => handleSelectCountry(country)}>{country.emoji} {country.name} ({country.phone})</div>
        })}</div>

    const countryCodeModal = countryCodeModalState && <div>{countryCodeSearchBar}
        {countryCodeList}</div>

    const toggleCountryCodeModal = () => {
        setCountryCodeModalState(p => !p)
        setModalBgState(p => !p)
    }

    const toggleModalBgState = () => {
        setModalBgState(p => !p)
        setCountryCodeModalState(p => !p)
    }

    const modalBg = modalBgState && <div className='bg-black h-[2000px] w-[2000px] opacity-20 top-0 -left-full absolute z-20' onClick={countryCodeModalState ? toggleCountryCodeModal : toggleModalBgState}></div >

    return (
        <Flex
            align="center"
            justify="center"
            height="60vh"
            flexDirection="column"
        >
            <Box>
                <Image width={200} marginBottom="10" src="https://assets.bonatra.com/images/bonatra-raush-logo.webp" alt="bonatra_raush_logo" />
                <Text className='text-center font-bold text-3xl text-[#ff385c] mb-6'>Say Hello!</Text>
            </Box>
            <Box p={8} maxW={{ base: 'full', md: 'md' }} borderWidth={1} borderRadius={8} boxShadow="lg">

                <form onSubmit={handleSubmit(onSubmit)} id="landing_page_hero_section">
                    <Flex justifyContent="center" alignItems="center" flexDirection="column" gap="8">
                        <Controller
                            name="mobile_no"
                            control={control}
                            defaultValue=""
                            rules={{ validate: validateMobileNumber }}
                            render={({ field }) => (
                                <div>

                                    <FormControl isInvalid={Boolean(errors.mobile_no)}>
                                        <Text className='font-bold'>Enter your Number, our experts will get back to you</Text>
                                        <Flex flexDirection={'row'} justifyContent={'space-between'}>
                                            <div className='w-[15%] h-[3.3125rem] rounded-[0.625rem] p-2 mt-[15px] border-[2px] border-solid border-[#D9D9D9]' onClick={toggleCountryCodeModal}>{selectedCountry?.phone}</div>
                                            <Input
                                                style={{
                                                    height: "3.3125rem",
                                                    border: "2px solid #D9D9D9",
                                                    borderRadius: "0.625rem",
                                                    marginTop: "15px",
                                                }}
                                                {...field}
                                                width='80%'
                                                id="mobile_no"
                                                type="tel"
                                                placeholder="Mobile Number"
                                                pattern="[0-9]*"
                                                colorScheme="cyan"
                                            />
                                        </Flex>
                                        {countryCodeModal}
                                        {modalBg}
                                        <FormErrorMessage>
                                            {errors?.mobile_no && errors?.mobile_no?.message && (
                                                <span>{errors.mobile_no.message as ReactNode}</span>
                                            )}
                                        </FormErrorMessage>

                                    </FormControl>
                                </div>
                            )}
                        />
                        <Button
                            id='say_hello_submit_btn'
                            colorScheme="whatsapp"
                            width="full"
                            height="3.3rem"
                            borderRadius="0.625rem"
                            type="submit"
                            marginTop="-4"
                        >
                            Submit
                        </Button>
                    </Flex>
                </form>
            </Box>
        </Flex>
    );
};

export default SayHello;