//import useWindowSize from '@/lib/hooks/useWIndowSize';
import useZustandStore from "@/lib/store/useZustandStore";
import { motion, AnimatePresence } from "framer-motion";
//import { useRouter } from 'next/router';
import React from "react";

const NavBurger2 = ({ mode }: { mode: string }) => {
  const navBurgerState = useZustandStore((state) => state.navBurgerState);
  const setNavBurgerState = useZustandStore((state) => state.setNavBurgerState);
  const closeNavBurger = useZustandStore(state => state.closeNavBurger)
  //convert this state to global, dont render page if true, only render nav menu
  return (
    <motion.nav
      initial={false}
      animate={navBurgerState ? "show" : "hidden"}
      className="relative cursor-pointer "
    >
      <motion.div
        onClick={() => {
          setNavBurgerState()
        }}
      // className={styles.burgerCircle}
      >
        <svg width="23" height="23" viewBox="0 0 23 23" style={{ zIndex: 100 }}>
          <Path
            mode={mode}
            variants={{
              hidden: { d: "M 2 2.5 L 20 2.5" },
              show: { d: "M 3 16.5 L 17 2.5" },
            }}
          // animate={toggleOpen ? "show" : "hidden"}
          />

          <Path
            mode={mode}
            d="M 2 9.423 L 20 9.423"
            variants={{
              hidden: { opacity: 1 },
              show: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            mode={mode}
            variants={{
              hidden: { d: "M 2 16.346 L 20 16.346" },
              show: { d: "M 3 2.5 L 17 16.346" },
            }}
          />
        </svg>
      </motion.div>
      <AnimatePresence>
        {navBurgerState && (
          <motion.div initial="hidden" animate="show" exit="exit"></motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default NavBurger2;

const Path = (props: any) => {
  const mode = props.mode;
  return (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke={mode === "dark" ? "#fff" : "#000"}
      strokeLinecap="round"
      {...props}
    />
  );
};
